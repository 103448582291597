var $ = require('jquery');
var jquery = require('jquery');
require('preloader');
$( function(){

	$('.logo-splash').addClass('animated fadeIn');

	
	//IMAGE PRELOADING
	new preLoader([
		'/wp-content/themes/vewstheme/assets/images/single-bg.jpg', 
		'/wp-content/themes/vewstheme/assets/images/vews-bg-cover.jpg'
	], {
		onComplete: function(loaded, errors){
			console.log('cache primed with:', loaded);
			errors && errors.length && console.log('these failed:', errors);
		}
	});

});

